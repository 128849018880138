import { createEntityAdapter, current as RTKCurrent } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { Project } from '../../interfaces';
import {
	type FileTagType,
	type ProjectChanges,
	type ProjectCreationType
} from '../../interfaces/Project.types';
import { searchApi } from '../search/searchSlice';
import { baseQuery } from '../stateUtils';

export const projectsAdapter = createEntityAdapter<Project>();

const basePath = '/services/projects/api/projects';

type AttachmentResultType = {
	id: string;
	fileUUID: string;
	name: string;
	tags: FileTagType[];
	project: { id: string };
};

const projectsApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			createProject: builder.mutation<{ id: string }, Partial<ProjectCreationType>>({
				invalidatesTags: ['Project'],
				query: (projectData) => ({
					body: projectData,
					method: 'POST',
					url: `${basePath}`
				})
			}),
			deleteAttachment: builder.mutation<{}, string>({
				invalidatesTags: ['Project'],
				query: (id) => ({
					method: 'DELETE',
					url: `/services/projects/api/attached-files/${id}`
				})
			}),
			editAttachment: builder.mutation<AttachmentResultType, { query?: string; editData: any }>({
				invalidatesTags: ['Project'],
				query: ({ editData }) => ({
					body: editData,
					method: 'PATCH',
					url: `/services/projects/api/attached-files/${editData.id}`
				})
			}),
			editProject: builder.mutation<Project, { query?: string; editData: any }>({
				invalidatesTags: ['Project'],
				async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
					let patchedResult;
					try {
						const { data: updatedProject } = await queryFulfilled;
						patchedResult = dispatch(
							searchApi.util.updateQueryData(
								'searchProjects',
								patch.query ?? '',
								(draftProjects) => {
									const currentState = RTKCurrent(draftProjects);
									Object.assign(draftProjects, {
										entities: { ...currentState.entities, [patch.editData.id]: updatedProject }
									});
								}
							)
						);
					} catch {
						patchedResult?.undo();
						// add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
					}
				},
				query: ({ editData }) => ({
					body: editData,
					method: 'PATCH',
					url: `${basePath}/${editData.id}`
				})
			}),
			fetchFileTags: builder.query<FileTagType[], {}>({
				query: () => `/services/projects/api/file-tags`
			}),
			fetchProject: builder.query<Project, string | undefined>({
				providesTags: (result, error, id) => [{ id, type: 'Project' as const }],
				query: (id) => `${basePath}/${id}`
			}),
			fetchProjectChanges: builder.query<ProjectChanges[], string | undefined>({
				providesTags: (result, error, id) => [{ id, type: 'Project' as const }],
				query: (id) => `${basePath}/${id}/changes`
			}),
			findProjectByGaebFileUUID: builder.query<Project, string>({
				query: (id) => `${basePath}/findByGaebFileUUID/${id}`
			})
		};
	},
	reducerPath: 'projects',
	tagTypes: ['Project']
});

export const {
	useCreateProjectMutation,
	useEditProjectMutation,
	useFetchProjectQuery,
	useFindProjectByGaebFileUUIDQuery,
	useFetchProjectChangesQuery,
	useFetchFileTagsQuery,
	useEditAttachmentMutation,
	useDeleteAttachmentMutation
} = projectsApi;

export const { select: selectFetchedProject } = projectsApi.endpoints.fetchProject;
export const { select: selectFetchedFileTags } = projectsApi.endpoints.fetchFileTags;
export { projectsApi };
