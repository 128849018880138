import { MainNavigation, MetaNavigation, Icon } from 'buderus-ui-components';
import { useTranslation } from 'react-i18next';

import getRoutes from '../../routes';
import styles from '../Header/Header.module.scss';
import NavLink from '../NavLink';

const HeaderPlaceholder = () => {
	const { t } = useTranslation();
	const { PROJECTS_CREATE, PROJECTS } = getRoutes(t);
	const currentPath = window.location.pathname.split('/')[1];
	const notificationsNumber = 0;
	const navLinks = [
		{
			active: currentPath === PROJECTS,
			children: <a href={`/${PROJECTS}`}>{t('common.projects')}</a>,
			id: 1
		}
	];
	const metaNavigationChildren = {
		leftChildren: <div className={styles.leftChildren} />,
		rightChildren: <div className={styles.username} />
	};

	const mainNavigationChildren = {
		leftChildren: navLinks.map((navLink) => <NavLink key={navLink.id} {...navLink} />),
		rightChildren: (
			<div className={styles.navbarRight}>
				<div key={0} className={styles.rightChildrenWrapper}>
					<NavLink active={false} id={1}>
						<a href={PROJECTS_CREATE}>+ {t('common.creation')}</a>
					</NavLink>
					<div className={styles.alertIcon}>
						<Icon height="25px" name="notificationBell" variant="white" width="25px" />
						{notificationsNumber > 0 && (
							<span className={styles.notificationNumber}>{notificationsNumber}</span>
						)}
					</div>
				</div>
			</div>
		)
	};

	return (
		<header className={styles.header}>
			<MetaNavigation
				leftChildren={metaNavigationChildren.leftChildren}
				rightChildren={metaNavigationChildren.rightChildren}
			/>
			<MainNavigation
				leftChildren={mainNavigationChildren.leftChildren}
				rightChildren={mainNavigationChildren.rightChildren}
			/>
			<div
				style={{
					backgroundColor: '#ffffff',
					height: '40px',
					width: '100%'
				}}
			/>
		</header>
	);
};

export default HeaderPlaceholder;
