// eslint-disable-next-line import/no-extraneous-dependencies
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import type { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';

// @ts-ignore
const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
	autoCapture: true
};
const appInsights = new ApplicationInsights({
	config: {
		connectionString: process.env.REACT_APP_INSIGHT_CONNECTION_STRING,
		enableAutoRouteTracking: true,
		extensionConfig: {
			[reactPlugin.identifier]: {
				history: browserHistory
			},
			[clickPluginInstance.identifier]: clickPluginConfig
		},
		extensions: [reactPlugin]
	}
});

appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
	// eslint-disable-next-line no-param-reassign
	envelope.tags = envelope.tags || {};
	// eslint-disable-next-line no-param-reassign
	envelope.tags['ai.cloud.role'] = 'UI';
});

appInsights.loadAppInsights();

export { appInsights, reactPlugin };
