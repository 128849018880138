import { createApi } from '@reduxjs/toolkit/query/react';

import type { IndirectCustomerGroup } from '../../interfaces';
import { baseQuery } from '../stateUtils';

const ICG_URL = '/services/indirectcustomers/api/indirect-customers-groups';

const indirectCustomerGroupApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			fetchIndirectCustomerGroup: builder.query<IndirectCustomerGroup, string>({
				query: (id) => `${ICG_URL}/${id}`
			}),
			fetchIndirectCustomerGroups: builder.query<IndirectCustomerGroup[], {}>({
				query: () => ICG_URL
			})
		};
	},
	reducerPath: 'indirectCustomerGroup'
});

export const { useFetchIndirectCustomerGroupQuery, useFetchIndirectCustomerGroupsQuery } =
	indirectCustomerGroupApi;

export const { select } = indirectCustomerGroupApi.endpoints.fetchIndirectCustomerGroups;

export { indirectCustomerGroupApi };
