import type { PropsWithChildren } from 'react';
import { Suspense } from 'react';

import styles from './AppWrapper.module.scss';
import HeaderPlaceholder from './HeaderPlaceholder';

// eslint-disable-next-line react/require-default-props
const WrapperPlaceholder = ({ isSuspended = false }: { isSuspended?: boolean }) => {
	const className = isSuspended ? styles.suspendedWrapper : styles.appWrapperMargin;
	return (
		<div className={className}>
			{isSuspended ? (
				<HeaderPlaceholder />
			) : (
				<>
					<div />
					<div />
					<div />
				</>
			)}
		</div>
	);
};

const AppWrapper = ({ children }: PropsWithChildren) => (
	<div className={styles.appWrapper}>
		<WrapperPlaceholder />
		<div className={styles.appContainer}>
			<Suspense fallback={<WrapperPlaceholder isSuspended />}>{children}</Suspense>
		</div>
		<WrapperPlaceholder />
	</div>
);

export default AppWrapper;
