import { useEffect, type PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import { appInsights } from '../../appInsight';

const PageTracking = ({ children }: PropsWithChildren) => {
	const location = useLocation();
	useEffect(() => {
		if (appInsights) {
			appInsights.trackPageView({ name: location.pathname });
		}
	}, [location]);

	return children;
};

export default PageTracking;
