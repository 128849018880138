import { createApi } from '@reduxjs/toolkit/query/react';

import type { UserMaintenanceType } from '../../interfaces';
import { baseQuery } from '../stateUtils';

const environmentInfoApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			fetchEnvironmentStage: builder.query<UserMaintenanceType, {}>({
				query: () => ({
					url: `api/open/environment-info/stage`
				})
			})
		};
	},
	reducerPath: 'environmentInfo'
});

export const { useFetchEnvironmentStageQuery } = environmentInfoApi;

export { environmentInfoApi };
