import { createSelector } from '@reduxjs/toolkit';
import type { EntityState, EntityId } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import type { HulkUser } from '../../interfaces';
import type { HulkUserType } from '../../types';
import { hulkUsersAdapter, initialHulkUsersState } from '../adapters';
import { baseQuery } from '../stateUtils';

const hulkUsersApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			fetchMembersOfLoggedInUserGroups: builder.query<
				EntityState<HulkUser, string>,
				'allHulkUsers'
			>({
				query: () => ({
					body: localStorage.getItem('HULK-CURRENT-LOGGED-USER'),
					method: 'POST',
					url: 'api/user-info/members'
				}),
				transformResponse: (response: [{ '@data.context': string; value: HulkUser[] }]) =>
					hulkUsersAdapter.setAll(
						initialHulkUsersState,
						response.map((graphData) => graphData.value).flat()
					)
			})
		};
	},
	reducerPath: 'hulkUsers'
});

export const { useLazyFetchMembersOfLoggedInUserGroupsQuery } = hulkUsersApi;

export const selectAllHulkUsers =
	hulkUsersApi.endpoints.fetchMembersOfLoggedInUserGroups.select('allHulkUsers');

export { hulkUsersApi };

export const selectHulkUsers = createSelector(selectAllHulkUsers, (hulkUsers) =>
	hulkUsers.status === 'fulfilled'
		? hulkUsers.data
		: {
				entities: {},
				ids: []
			}
);

const createDepartmentField = (displayName: string | undefined) => {
	if (displayName) {
		return displayName.split(' ')[2].replace('(', '').replace(')', '');
	}
	return '';
};

export const selectHulkUsersByName = createSelector(
	[selectHulkUsers, (_: EntityState<HulkUserType, string>, searchParam: string) => searchParam],
	(hulkUsers: EntityState<HulkUserType, EntityId>, searchParam: string) => {
		if (searchParam) {
			const loweredSearchParams = searchParam.toLowerCase();
			const filteredUsers = Object.values(hulkUsers.entities).filter(
				(hulkUser) =>
					hulkUser?.displayName.toLowerCase().includes(loweredSearchParams) ||
					hulkUser?.mail?.toLowerCase().includes(loweredSearchParams) ||
					hulkUser?.userPrincipalName.toLowerCase().includes(loweredSearchParams)
			);
			return filteredUsers.length
				? {
						data: {
							entities: filteredUsers.reduce(
								(acc, curr) => ({
									...acc,
									[`${curr?.userPrincipalName}`]: {
										...curr,
										department: createDepartmentField(curr?.displayName)
									}
								}),
								{}
							),
							ids: filteredUsers.map((user) => user.userPrincipalName)
						}
					}
				: {
						data: {
							entities: {},
							ids: []
						}
					};
		}
		return {
			data: {
				entities: hulkUsers.ids.reduce(
					(acc, curr) => ({
						...acc,
						[curr]: {
							...hulkUsers.entities[curr],
							department: createDepartmentField(hulkUsers.entities[curr]?.displayName)
						}
					}),
					{}
				),
				ids: hulkUsers.ids
			}
		};
	}
);
