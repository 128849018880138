import { configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { aiPredictionsApi } from './aiPredictions/aiPredictionsSlice';
import { cacheApi } from './cache/cacheSlice';
import { contactPersonApi } from './contactPerson/contactPersonSlice';
import { contactsApi } from './customers/customersSlice';
import { environmentInfoApi } from './environmentInfo/environmentInfoSlice';
import { exportToSapApi } from './exportToSap/exportToSapSlice';
import { filePersistorApi } from './filePersistor/filePersistorSlice';
import { hulkUsersApi } from './hulkUsers/hulkUsersSlice';
import { indirectCustomerGroupApi } from './indirectCustomerGroup/indirectCustomerGroupSlice';
import { postalCodesApi } from './postalCodes/postalCodesSlice';
import { powerAutomateApi } from './powerAutomate/powerAutomateSlice';
import { productMasterDataApi } from './productMasterData/productMasterDataSlice';
import attachmentsSlice from './projects/attachmentsSlice';
import { projectsApi } from './projects/projectsSlice';
import searchAiPredictionsProductsSlice from './search/searchAiPredictionsProductsSlice';
import { searchApi } from './search/searchSlice';
import { shoppingCartsApi } from './shoppingCarts/shoppingCartsSlice';
import { rtkQueryErrorLogger } from './stateUtils';
import { supplierCommunicationApi } from './supplierCommunication/supplierCommunication';
import { supplierProductProposalApi } from './supplierProductProposal/supplierProductProposal';
import { tenderDocContentApi } from './tenderDocument/tenderDocumentSlice';
import tenderItemsState from './tenderItems/tenderItemsState';
import tenderItemsSuppliersState from './tenderItems/tenderItemsSuppliersState';
import uiReducer from './ui/uiSlice';
import { userRefinementApi } from './userRefinement/userRefinementSlice';
import { userSettingsApi } from './userSettings/userSettingsSlice';

// CREATING STORE
export const store = configureStore({
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ['filePersistor/executeQuery/fulfilled'],
				ignoredPaths: ['filePersistor.queries']
			}
		})
			.concat(aiPredictionsApi.middleware)
			.concat(cacheApi.middleware)
			.concat(contactPersonApi.middleware)
			.concat(contactsApi.middleware)
			.concat(hulkUsersApi.middleware)
			.concat(indirectCustomerGroupApi.middleware)
			.concat(shoppingCartsApi.middleware)
			.concat(postalCodesApi.middleware)
			.concat(powerAutomateApi.middleware)
			.concat(projectsApi.middleware)
			.concat(searchApi.middleware)
			.concat(tenderDocContentApi.middleware)
			.concat(exportToSapApi.middleware)
			.concat(userRefinementApi.middleware)
			.concat(supplierCommunicationApi.middleware)
			.concat(filePersistorApi.middleware)
			.concat(userSettingsApi.middleware)
			.concat(productMasterDataApi.middleware)
			.concat(supplierProductProposalApi.middleware)
			.concat(environmentInfoApi.middleware)
			.concat(rtkQueryErrorLogger),
	reducer: {
		aiPredictionsProducts: searchAiPredictionsProductsSlice,
		attachments: attachmentsSlice,
		[aiPredictionsApi.reducerPath]: aiPredictionsApi.reducer,
		[cacheApi.reducerPath]: cacheApi.reducer,
		[contactPersonApi.reducerPath]: contactPersonApi.reducer,
		[contactsApi.reducerPath]: contactsApi.reducer,
		[hulkUsersApi.reducerPath]: hulkUsersApi.reducer,
		[indirectCustomerGroupApi.reducerPath]: indirectCustomerGroupApi.reducer,
		[shoppingCartsApi.reducerPath]: shoppingCartsApi.reducer,
		[postalCodesApi.reducerPath]: postalCodesApi.reducer,
		[powerAutomateApi.reducerPath]: powerAutomateApi.reducer,
		[projectsApi.reducerPath]: projectsApi.reducer,
		[searchApi.reducerPath]: searchApi.reducer,
		[tenderDocContentApi.reducerPath]: tenderDocContentApi.reducer,
		[exportToSapApi.reducerPath]: exportToSapApi.reducer,
		[userRefinementApi.reducerPath]: userRefinementApi.reducer,
		[supplierCommunicationApi.reducerPath]: supplierCommunicationApi.reducer,
		[filePersistorApi.reducerPath]: filePersistorApi.reducer,
		[userSettingsApi.reducerPath]: userSettingsApi.reducer,
		[productMasterDataApi.reducerPath]: productMasterDataApi.reducer,
		[supplierProductProposalApi.reducerPath]: supplierProductProposalApi.reducer,
		[environmentInfoApi.reducerPath]: environmentInfoApi.reducer,
		tenderItems: tenderItemsState,
		tenderSuppliers: tenderItemsSuppliersState,
		ui: uiReducer
	}
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

// Use throughout app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// EXPORTING ACTIONS
export {
	useGenerateEmlMutation,
	useCreateProjectDataMutation,
	useSendEmailMutation,
	useSendBulkEmailMutation,
	useFetchSentSupplierInquiresQuery
} from './supplierCommunication/supplierCommunication';

export {
	useCreateUserSettingsPropertiesMutation,
	useFetchUserSettingsPropertiesQuery,
	useEditUserSettingsPropertiesMutation
} from './userSettings/userSettingsSlice';

export {
	useFetchAKTsQuery,
	useFetchCountriesQuery,
	useFetchProjectStatusQuery,
	useFetchTenderTypesQuery
} from './cache/cacheSlice';

export {
	useCreateContactPersonMutation,
	useEditContactPersonMutation,
	useFetchSupplierContactsAddressQuery
} from './contactPerson/contactPersonSlice';

export {
	useCreateCustomerMutation,
	useCreateSupplierMutation,
	useFetchContactPersonQuery,
	useFetchCustomerByIdsQuery,
	useFetchCustomerQuery
} from './customers/customersSlice';

export {
	useFetchIndirectCustomerGroupQuery,
	useFetchIndirectCustomerGroupsQuery
} from './indirectCustomerGroup/indirectCustomerGroupSlice';

export {
	useFetchAllShoppingCartsQuery,
	useFetchOneShoppingCartQuery
} from './shoppingCarts/shoppingCartsSlice';

export {
	useFetchPostalCodeQuery,
	useFetchPostalCodeAdressesQuery,
	useFetchPostalCodeAktQuery
} from './postalCodes/postalCodesSlice';

export { useCallPowerAutomateMutation } from './powerAutomate/powerAutomateSlice';

export {
	useCreateProjectMutation,
	useEditProjectMutation,
	useFetchProjectQuery,
	useFindProjectByGaebFileUUIDQuery,
	useFetchProjectChangesQuery,
	useFetchFileTagsQuery
} from './projects/projectsSlice';

export { deleteProjectAttachments, updateProjectAttachments } from './projects/attachmentsSlice';

export {
	useSearchAddressesQuery,
	useSearchContactsQuery,
	useSearchCustomersQuery,
	useSearchProductsQuery,
	useSearchProjectsQuery,
	useSearchSuppliersQuery
} from './search/searchSlice';

export {
	toggleTenderItemsTreeEditingPrice,
	toggleTenderItemsTreeEditingSuppliers,
	toggleTenderItemsTreeExportColumn,
	toggleShouldOpenBlockerModal,
	toggleMultiselection,
	addSupplierForMultiselection,
	addProjectForAiPredictionStatus,
	deleteProjectForAiPredictionStatus
} from './ui/uiSlice';

export {
	useFetchTenderRefinementsQuery,
	useFetchItemRefinementsFromDocQuery,
	useEditItemRefinementMutation,
	useCreateSupplierAssignmentMutation,
	useDeleteSupplierAssignmentMutation,
	useEditProductAssigmentMutation,
	useCreateProductAssigmentMutation,
	useDeleteProductAssigmentMutation,
	useCreateProductAssigmentBulkMutation,
	useFetchProductAssigmentsQuery,
	useDeleteSupplierAssignmentBulkMutation,
	useFetchShoppingCardsQuery
} from './userRefinement/userRefinementSlice';

export {
	useFetchTenderDocumentHeaderContentQuery,
	useLazyFetchTemplateQuery,
	usePollingTenderDocStatusQuery,
	useUploadTenderDocContentMutation,
	useUploadTenderDocContentAttachmentsMutation,
	useLazyDeleteTenderDocContentAttachmentQuery
} from './tenderDocument/tenderDocumentSlice';

export { useLazyFetchMembersOfLoggedInUserGroupsQuery } from './hulkUsers/hulkUsersSlice';

export { useFetchExportsQuery, useCreateVersionMutation } from './exportToSap/exportToSapSlice';

export { useFetchEnvironmentStageQuery } from './environmentInfo/environmentInfoSlice';

export {
	useLazyDownloadFileQuery,
	useLazyFetchFileQuery
} from './filePersistor/filePersistorSlice';

// EXPORTING SELECTORS
export {
	selectAKTs,
	selectCountries,
	selectProjectStatus,
	selectTenderTypes
} from './cache/cacheSlice';

export { selectFetchedUserSettingsProperties } from './userSettings/userSettingsSlice';

export { select as selectIndirectCustomerGroups } from './indirectCustomerGroup/indirectCustomerGroupSlice';

export { selectProjectAttachments } from './projects/attachmentsSlice';

export {
	selectSearchedAddresses,
	selectSearchedContacts,
	selectSearchedCustomers,
	selectSearchedProducts,
	selectSearchedProjects,
	selectSearchedSuppliers
} from './search/searchSlice';

export {
	select as selectPostalCodes,
	selectPostalCodeAdresses
} from './postalCodes/postalCodesSlice';

export {
	selectIsEditingPriceOn,
	selectIsEditingSuppliersOn,
	selectIsExportColumnOn,
	selectIsOpenBlockerModal,
	selectAddedSupplieForMultiselection,
	selectMultiselectedTenders,
	selectProjectsForAiPredictionStatus
} from './ui/uiSlice';

export { selectFetchedCustomer, selectFetchedCustomerById } from './customers/customersSlice';

export { selectFetchedProject, selectFetchedFileTags } from './projects/projectsSlice';

export { selectTemplate } from './tenderDocument/tenderDocumentSlice';

export {
	selectItemsFromSupplierShoppingCart,
	selectShoppingCarts
} from './shoppingCarts/shoppingCartsSlice';

export {
	selectAllHulkUsers,
	selectHulkUsers,
	selectHulkUsersByName
} from './hulkUsers/hulkUsersSlice';

export {
	selectItemsFromShoppingCards,
	selectShoppingCards,
	selectTenderRefinements
} from './userRefinement/userRefinementSlice';
